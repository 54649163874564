window.addEventListener('DOMContentLoaded', () => {
  const scrollWrapper = document.querySelector('.scroll-sticky-wrapper')
  if (scrollWrapper) {
    scrollWrapper.insertAdjacentHTML("beforeend", document.querySelector('.scroll-block img').outerHTML)
    document.querySelector('.scroll-sticky-wrapper img').classList.add('active')
    const options = {
      rootMargin: '0px 0px -50px 0px',
      threshold: 1,
    }
    let observer = new IntersectionObserver(entries => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          const scrollImage = entry.target.querySelector('img');
          if (scrollWrapper.firstElementChild && +scrollWrapper.firstElementChild.getAttribute('data-id') !== +scrollImage.getAttribute('data-id')) {
            scrollWrapper.innerHTML = '';
          }
          if (!scrollWrapper.firstElementChild) {
            scrollWrapper.insertAdjacentHTML("beforeend", scrollImage.outerHTML);
          }
          setTimeout(() => {
            document.querySelector('.scroll-sticky-wrapper img').classList.add('active')
          }, 50)
        }
      });
    }, options);
    const targets = document.querySelectorAll('.scroll-block')
    targets.forEach(target => {
      observer.observe(target);
    })
  }
});