window.addEventListener('DOMContentLoaded', () => {
    const partnersSwiper = new Swiper('.partners-swiper', {
        loop: true,
        autoplay: true,
        spaceBetween: 40,
        slidesPerView: 2,
        breakpoints: {
            476: {
                slidesPerView: 4,
                spaceBetween: 50
            },
            992: {
                slidesPerView: 5,
                spaceBetween: 30
            },
            1400: {
                slidesPerView: 7,
                spaceBetween: 80,
            }
        }
    });
    const testimonialSwiper = new Swiper('.testimonial-swiper', {
        spaceBetween: 30,
        effect: "fade",
        navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
        },
    });

    const textLimitedItems = document.querySelectorAll('.text-limited');
    setTimeout(checkTextHeight, 1000);

    function checkTextHeight() {
        textLimitedItems.forEach(function (el) {
            if (el.offsetHeight > 325) {
                el.classList.add('not-expanded');
                el.parentElement.querySelector('a.read-more').classList.add('show');
            }
        })
    }

    const readMoreBtns = document.querySelectorAll('a.read-more');

    for (let btn of readMoreBtns) {
        btn.addEventListener('click', (e) => {
            const card = e.target.parentElement.querySelector('.text-limited');
            
            if (card.classList.contains('not-expanded')) {
                card.classList.remove('not-expanded');
            } else {
                card.classList.add('not-expanded');
            }
            if (e.target.innerText == 'Read more') {
                e.target.innerText = 'Read less';
            }
            else {
                e.target.innerText = 'Read more';
            }
        })
    }
});



Vue.component('v-select', VueSelect.VueSelect);

if (document.getElementById('app')) {
    new Vue({
        el: '#app',
        data: {
            options: [
                'Lorem ipsum1',
                'Lorem ipsum2',
                'Lorem ipsum3'
            ],
            selected: 'Select Category'
        }
    })
}


//const videoElement = document.querySelector('.video-section video');
const videoElement2 = document.querySelector('.video-section iframe');
const playPauseButton = document.querySelector('.custom-play-button');

playPauseButton.addEventListener('click', () => {
	playPauseButton.classList.toggle('playing');
    var iframeSrc = videoElement2.getAttribute("src");
    videoElement2.setAttribute("src", iframeSrc + '?autoplay=1');
    playPauseButton.classList.add('d-none');
    //videoElement.classList.toggle('playing');
	if (playPauseButton.classList.contains('playing')) {
		//videoElement.play();
	}
	else {
		//videoElement.pause();
	}
});

// videoElement.addEventListener('ended', () => {
// 	playPauseButton.classList.remove('playing');
// });

