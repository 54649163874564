var header = document.querySelector('header');
var homepageBanner = document.querySelector('.homepage-banner');
let offsetTopVal = 5;
if (window.innerWidth <= 576) {
    offsetTopVal = 25;
}

const checkHeaderTopPos = () => {
    if (homepageBanner) {
        if (header.offsetTop > offsetTopVal) {
            header.classList.add('bg-white')
            header.classList.add('shadow')
        } else {
            header.classList.remove('bg-white')
            header.classList.remove('shadow')
        }
    } else {
        if (!header.classList.contains("login-header")) {
            header.classList.add('bg-white')
            header.classList.add('shadow')
        }
    }
}

window.addEventListener('DOMContentLoaded', () => {
    checkHeaderTopPos();

    window.addEventListener('scroll', () => {
        checkHeaderTopPos();
    })
});